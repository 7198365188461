import {getSubdomain} from "./service/common.services";
import config from './config.json';
let DATA
console.log(getSubdomain(window.location.hostname))
if(getSubdomain(window.location.hostname) === ""){
    DATA=config.localhost

}
else {
    DATA=config[getSubdomain(window.location.hostname)]

}



export const ES_INDEX=DATA.index
export const API_PATH=DATA.apipath
